import { AgCharts } from 'ag-charts-react'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import { baseSuccessToastOptions } from '../../../utils/functions.utils'
import { ActivatedAssetsVolumeDto } from '../../../utils/types/types'
import LoadingChart from '../charts/LoadingChart.component'

const AssetVolumeChart = (): ReactElement => {
    const translate = useTranslation().t
    const toast = useToast()
    const [assetData, setAssetData] = useState<ActivatedAssetsVolumeDto>()
    const [assetVolume, setAssetVolume] =
        useState<{ value: any; status: string }[]>()
    const [loading, setLoading] = useState<boolean>(true)

    const getAssetVolume = async (): Promise<void> => {
        setLoading(true)
        try {
            const response: any = await generalGetAPI(
                API_ENDPOINTS.dashboardActivatedAssetVolume
            )
            if (response?.data && response?.isOk) {
                setAssetData(response.data)
                setAssetVolume(
                    Object.entries(response.data).map((entries) => ({
                        value: entries[1],
                        status: translate(entries[0]),
                    }))
                )
            }
            if (!response.isOk) {
                toast(baseSuccessToastOptions(response.message))
            }
        } catch (error: any) {
            toast(baseSuccessToastOptions(error?.message))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAssetVolume()
    }, [])

    const createOptions = (data: any): any => ({
        data,
        series: [
            {
                type: 'donut',
                angleKey: 'value',
                calloutLabelKey: 'status',
                // title: {
                //     text: 'Asset Contracts',
                // },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: translate('activeAssets'),
                        fontSize: 16,
                        margin: 10,
                    },
                    {
                        text: assetData?.activatedAssetsNumber.toString(),
                        fontSize: 24,
                    },
                ],
                strokeWidth: 3,
            },
        ],
    })

    if (loading) {
        return <LoadingChart />
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            overflow="hidden"
        >
            <Box flex="1" display="flex">
                <AgCharts
                    options={createOptions(assetVolume)}
                    style={{ width: '98%', height: '98%' }}
                />
            </Box>
        </Box>
    )
}

export default AssetVolumeChart
