import { Flex, Spinner } from '@chakra-ui/react'
import { ReactElement } from 'react'

export default function LoadingChart(): ReactElement {
    return (
        <Flex
            alignItems="center"
            flex="1"
            justifyContent="center"
            h={'full'}
            w={'full'}
            minH={'320px'}
        >
            <Spinner size="xl" color="teal.500" />
        </Flex>
    )
}
