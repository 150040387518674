import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
    Box,
    chakra,
    Collapse,
    Flex,
    useColorModeValue,
} from '@chakra-ui/react'
import {
    faChartLine,
    faFileContract,
    faGear,
    faLayerGroup,
    faMoneyBill,
    faUsers,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IBaseRoute } from '../../../../../app/routes/base.routes'
import FINANCIAL_ROUTES from '../../../../../app/routes/financial.routes'
import SETUP_ROUTES from '../../../../../app/routes/setup.routes'
import ErrorBoundaryWrapper from '../../../../../features/errorBoundary/ErrorBoundary.feature'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../../services/API/general.api'
import { useLayout } from '../../../../../services/contexts/LayoutServices.context'
import { useModule } from '../../../../../services/contexts/Module.context'
import { usePartner } from '../../../../../services/contexts/partner.context'
import { useProduct } from '../../../../../services/contexts/Product.context'
import { useUser } from '../../../../../services/contexts/user.context'
import { useERP } from '../../../../../services/erp/ERP.services'
import {
    ModuleType,
    ProductDTO,
    UserGroup,
} from '../../../../../utils/types/types'
import { ISidebarLinks } from '../sidebar.config'

export default function NavigationLinks(): ReactElement {
    const location = useLocation()
    const navigate = useNavigate()
    const translate = useTranslation().t
    const { hasAccess } = useModule()
    const [pathname, setPathname] = useState('')
    const { activePartner } = usePartner()
    const { activeSetup } = useERP()
    const [isContractFinanceVisible, setIsContractFinanceVisible] =
        useState<boolean>(false)
    const { isMobile, closeMenu } = useLayout()
    const [isUsageEnabled, setUsageEnabled] = useState<boolean>(false)
    const { products } = useProduct()
    const { user } = useUser()

    function getFinancialLinks(): ISidebarLinks[] {
        const listLinks: ISidebarLinks[] = []
        FINANCIAL_ROUTES(activeSetup).forEach((route: any) => {
            if (
                (!isContractFinanceVisible &&
                    route?.path === 'contract-finance') ||
                (!isUsageEnabled && route?.path === 'usage') ||
                (!hasAccess(ModuleType.ManualInvoicing) &&
                    route?.path === 'invoices') ||
                route?.path?.includes(':')
            ) {
                return
            }

            listLinks.push({
                link: `/${route?.path}`,
                title: translate(route?.translateKey),
            })
        })
        return listLinks
    }

    const plainTextSideBar = {
        color: useColorModeValue('primary.50', 'secondary.50'),
    }

    const buttonStyle = {
        bg: useColorModeValue('primary.400', 'secondary.750'),
        color: useColorModeValue('primary.50', 'secondary.50'),
        fontWeight: 600,
        _hover: {
            bg: useColorModeValue('primary.550', 'secondary.700'),
            color: useColorModeValue('primary.50', 'secondary.50'),
            fontWeight: 600,
        },
        mb: 8,
    }

    const activeLinkStyle = {
        color: useColorModeValue('primary.50', 'secondary.50'),
        bg: useColorModeValue('primary.700', 'secondary.750'),
        fontWeight: 'bold',
    }

    function getStyles(item: ISidebarLinks): any {
        if (item?.isButton) {
            return buttonStyle
        }
        if (pathname.includes(item?.link)) {
            return activeLinkStyle
        }
        return plainTextSideBar
    }

    const SIDEBAR_LINKS: ISidebarLinks[] = [
        {
            link: 'new-offer',
            title: translate('newOffer'),
            isButton: true,
        },
        {
            link: 'dashboard',
            title: translate('dashboard'),
            icon: faChartLine,
        },
        {
            link: 'contracts',
            icon: faFileContract,
            title: translate('contracts'),
            menuItems: [
                {
                    link: '?status=All',
                    title: translate('all'),
                },
                {
                    link: '?status=Offer',
                    title: translate('offer'),
                },
                {
                    link: '?status=Active',
                    title: translate('active'),
                },
                {
                    link: '?status=UnderClosing',
                    title: translate('underClosing'),
                },
                {
                    link: '?status=Closed',
                    title: translate('closed'),
                },
                {
                    link: '?status=Cancelled',
                    title: translate('cancelled'),
                },
            ],
        },
        {
            icon: faLayerGroup,
            link: 'inventory',
            title: translate('inventory'),
            menuItems: [
                {
                    link: '?status=all',
                    title: translate('all'),
                },
                {
                    link: '?status=1',
                    title: translate('ready'),
                },
                {
                    link: '?status=3',
                    title: translate('inUse'),
                },
                {
                    link: '?status=2',
                    title: translate('closed'),
                },
            ],
        },
        {
            icon: faUsers,
            link: 'customers',
            title: translate('customers'),
        },
        {
            icon: faMoneyBill,
            link: 'financial',
            title: translate('financial'),
            isHidden: user.userGroup <= UserGroup.RegularUser,
            menuItems: getFinancialLinks(),
        },
        {
            icon: faGear,
            link: 'setup',
            title: translate('setup'),
            isHidden: user.userGroup <= UserGroup.RegularUser,
            menuItems: getSetupLinks(),
        },
    ]

    function getSetupLinks(): Array<ISidebarLinks> {
        const setupLinks: Array<ISidebarLinks> = []
        let setupRoutes = SETUP_ROUTES

        const hasInsuranceAccess = hasAccess(ModuleType.Insurance)
        if (!hasInsuranceAccess) {
            setupRoutes = SETUP_ROUTES.filter(
                (elem) => elem.translateKey !== 'insurance'
            )
        }

        setupRoutes.forEach((route: IBaseRoute) => {
            if (!route?.hideFromMenu) {
                setupLinks.push({
                    link: `/${(route.path as string).replace('/*', '')}`,
                    title: translate(route?.translateKey as string),
                })
            }
        })

        return setupLinks
    }

    const getContractFinanceVisibility = async (): Promise<void> => {
        setIsContractFinanceVisible(false)
        const response = await generalGetAPI(
            `${API_ENDPOINTS.parameter}?ParameterKey=IsContractFinanceVisible`
        )
        if (response.data?.length > 0) {
            setIsContractFinanceVisible(
                Boolean(response?.data[0]?.value) ||
                    hasAccess(ModuleType.ContractFinance)
            )
        }
    }

    const getContractUsageEnabled = async (): Promise<void> => {
        if (products) {
            const isEnabled = products?.some(
                (product: ProductDTO) => !!product.isUsageEnabled
            )
            setUsageEnabled(isEnabled)
        }
    }

    useEffect(() => {
        setPathname(`${location.pathname}${location.search}`)
    }, [location])

    useEffect((): void => {
        if (activePartner?.id) {
            getContractFinanceVisibility()
            getContractUsageEnabled()
        }
    }, [activePartner?.id])

    return (
        <ErrorBoundaryWrapper id="navigation-links-component-error">
            {SIDEBAR_LINKS.map((item: ISidebarLinks, index) => {
                if (item.isHidden) return <Box key={index}></Box>
                return (
                    <Box key={index} style={{ textDecoration: 'none' }}>
                        <Flex
                            mb={item?.isButton ? 4 : 2}
                            onClick={(event) => {
                                const navigateLink = item?.menuItems?.length
                                    ? `${item?.link}${item?.menuItems[0]?.link}`
                                    : item?.link

                                navigate(navigateLink)
                                if (event && isMobile) {
                                    closeMenu()
                                }
                            }}
                            mt={item?.isButton ? 4 : 'inherit'}
                            align="center"
                            p="3"
                            mx="4"
                            borderRadius={item?.isButton ? 50 : 'lg'}
                            role="group"
                            cursor="pointer"
                            textTransform={'capitalize'}
                            justifyContent={
                                item?.isButton ? 'center' : 'space-between'
                            }
                            {...getStyles(item)}
                        >
                            <chakra.span>
                                {item.icon && (
                                    <chakra.span mr={'1rem'}>
                                        <FontAwesomeIcon
                                            width={'1rem'}
                                            icon={item.icon}
                                        />
                                    </chakra.span>
                                )}
                                {item?.title}
                            </chakra.span>
                            {item?.menuItems?.length && (
                                <>
                                    {pathname.includes(item?.link) ? (
                                        <ChevronUpIcon />
                                    ) : (
                                        <ChevronDownIcon />
                                    )}
                                </>
                            )}
                        </Flex>
                        <Collapse
                            in={
                                pathname.includes(item?.link) &&
                                !!item?.menuItems?.length
                            }
                            animateOpacity
                        >
                            <Flex mt={1} mb={4} flexDirection={'column'}>
                                {!item?.isButton &&
                                    item.menuItems?.map((elem) => (
                                        <Box
                                            key={elem?.link}
                                            ml={8}
                                            textTransform={'capitalize'}
                                            px={3}
                                            borderLeftWidth={'1.5px'}
                                            borderLeftStyle={'solid'}
                                            cursor={'pointer'}
                                            onClick={(event): void => {
                                                const navigateLink = `${item?.link}${elem?.link}`
                                                navigate(navigateLink)
                                                if (event && isMobile) {
                                                    closeMenu()
                                                }
                                            }}
                                        >
                                            <Box
                                                my={1}
                                                mx={2}
                                                py={2}
                                                px={3}
                                                borderRadius={'lg'}
                                                {...getStyles(elem)}
                                            >
                                                {elem?.title}
                                            </Box>
                                        </Box>
                                    ))}
                            </Flex>
                        </Collapse>
                    </Box>
                )
            })}
        </ErrorBoundaryWrapper>
    )
}
