import {
    MonthlyFinancialSummary,
    ProductType,
} from '../../../utils/types/types'

export interface TransformedData {
    month: string
    expected: number
    committed: number
}

export interface MRRLineChartProps {
    selectedProduct?: ProductType
    contractTemplate?: number
    startDate: Date
    endDate: Date
}

// Utility function to format large numbers
export const formatNumber = (number: number) => {
    if (number >= 1000000) return `${(number / 1000000).toFixed(1)}M`
    if (number >= 1000) return `${(number / 1000).toFixed(1)}k`
    return number.toString()
}

// Transform API data into a format suitable for the chart
export const transformData = (
    apiData: MonthlyFinancialSummary[]
): TransformedData[] => {
    const sortedData = apiData.sort(
        (a, b) => a.year - b.year || a.month - b.month
    )

    return sortedData.map((item) => ({
        month: new Date(item.year, item.month).toLocaleString('default', {
            month: 'short',
        }),
        expected: item.revenue,
        committed: item.revenue - 20,
    }))
}

export function monthlyRevenueOptions(data: any): any {
    return {
        data,
        series: [
            {
                type: 'line',
                xKey: 'month',
                yKey: 'expected',
                yName: 'Expected MRR',
                strokeWidth: 2,
                marker: {
                    enabled: true,
                    size: 6,
                },
                highlightStyle: {
                    item: {
                        fill: '#ff4081',
                        stroke: '#ff4081',
                    },
                },
            },
            {
                type: 'line',
                xKey: 'month',
                yKey: 'committed',
                yName: 'Committed MRR',
                strokeWidth: 2,
                marker: {
                    enabled: true,
                    size: 6,
                },
                highlightStyle: {
                    item: {
                        fill: '#3f51b5',
                        stroke: '#3f51b5',
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                title: {
                    text: 'Month',
                    fontSize: 14,
                },
                label: {
                    rotation: -45, // Rotate labels for better readability
                },
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: 'Revenue ($)',
                    fontSize: 14,
                },
                label: {
                    formatter: (params: { value: number }) =>
                        `$${(params.value / 1000).toFixed(1)}k`, // Format values in thousands
                },
            },
        ],
        legend: {
            enabled: true,
            position: 'bottom',
        },
        zoom: {
            enabled: true, // Enable zooming
            axes: 'x', // Limit zooming to the x-axis
        },
        navigator: {
            enabled: true, // Enable a navigator for better control
        },
        tooltip: {
            renderer: (params: any) => ({
                title: params.xValue,
                content: `${
                    params.seriesName
                }: $${params.yValue.toLocaleString()}`,
            }),
        },
    }
}
