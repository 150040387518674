/* eslint-disable @typescript-eslint/no-shadow */
import { ReactElement, useEffect, useState } from 'react'
import {
    Box,
    Flex,
    Heading,
    IconButton,
    SimpleGrid,
    Button,
    Stack,
} from '@chakra-ui/react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FiMaximize, FiSettings, FiLock, FiUnlock } from 'react-icons/fi'
import camelCase from 'lodash/camelCase'
import { useTranslation } from 'react-i18next'
import PageContainer from '../../components/pageContainer/PageContainer.component'
import { useProduct } from '../../services/contexts/Product.context'
import { ProductType } from '../../utils/types/types'
import ActivatedContractsChart from './charts/creationContracts.chart'
import MRRLineChart from './charts/monthlyRevenue.chart'
import AssetVolumeChart from './components/AssetVolume.component'
import ContractsPaymentsPerMonthChart from './components/ActualContractsPaymentsPerMonth.component'
import CustomerContractsPerStatusChart from './components/CustomerContractsPerStatus.component'
import ChurnRateChart from './components/ChurnRate.component'
import ForecastContractsPaymentsPerMonthChart from './components/ForecastContractsPaymentsPerMonth.component'
import ChartDrawer from './ChartDrawer.component'
import FullScreenChart from './FullscreenChart.component'

interface ChartConfig {
    startDate: Date
    endDate: Date
    selectedProduct: ProductType | undefined
    contractTemplateId: number | null
}

export default function DashboardPage(): ReactElement {
    const { products, getProducts } = useProduct()
    const [fullScreenChart, setFullScreenChart] = useState<string | null>(null)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedChartForConfig, setSelectedChartForConfig] = useState<
        string | null
    >(null)
    const [isDragDisabled, setIsDragDisabled] = useState(false) // To toggle dragging
    const translate = useTranslation().t

    const [chartConfigs, setChartConfigs] = useState<Record<string, any>>({
        activatedContracts: {
            startDate: new Date(
                new Date().setFullYear(new Date().getFullYear() - 1)
            ),
            endDate: new Date(),
            selectedProduct: undefined,
            contractTemplateId: null,
        },
        churnRate: {
            selectedProduct: undefined,
            contractTemplateId: null,
        },
        assetVolume: {
            startDate: new Date(),
            endDate: new Date(),
            selectedProduct: undefined,
            contractTemplateId: null,
        },
        mrrLine: {
            startDate: new Date(
                new Date().setFullYear(new Date().getFullYear() - 1)
            ),
            endDate: new Date(),
            selectedProduct: undefined,
            contractTemplateId: null,
        },
        contractsPayments: {
            startDate: new Date(),
            endDate: new Date(),
            selectedProduct: undefined,
            contractTemplateId: null,
        },
        customerContracts: {
            startDate: new Date(),
            endDate: new Date(),
            selectedProduct: undefined,
            contractTemplateId: null,
        },
        forecastContracts: {
            startDate: new Date(),
            endDate: new Date(),
            selectedProduct: undefined,
            contractTemplateId: null,
        },
    })

    const initialCharts = [
        { id: 'activatedContracts', component: ActivatedContractsChart },
        { id: 'churnRate', component: ChurnRateChart },
        { id: 'assetVolume', component: AssetVolumeChart },
        { id: 'mrrLine', component: MRRLineChart },
        { id: 'contractsPayments', component: ContractsPaymentsPerMonthChart },
        { id: 'customerContracts', component: CustomerContractsPerStatusChart },
        {
            id: 'forecastContracts',
            component: ForecastContractsPaymentsPerMonthChart,
        },
    ]
    const [charts, setCharts] = useState(initialCharts)

    useEffect(() => {
        getProducts()
    }, [])

    useEffect(() => {
        const savedOrder = localStorage.getItem('chartOrder')
        if (savedOrder) {
            const parsedOrder = JSON.parse(savedOrder)
            const reorderedCharts = parsedOrder
                .map((id: string) =>
                    initialCharts.find((chart) => chart.id === id)
                )
                .filter((chart: any) => chart)
            setCharts(reorderedCharts)
        }
    }, [])

    useEffect(() => {
        const chartOrder = charts.map((chart) => chart.id)
        localStorage.setItem('chartOrder', JSON.stringify(chartOrder))
    }, [charts])

    const onDragEnd = (result: any) => {
        const { destination, source } = result

        if (!destination) return
        const reorderedCharts = Array.from(charts)
        const [movedChart] = reorderedCharts.splice(source.index, 1)
        reorderedCharts.splice(destination.index, 0, movedChart)

        setCharts(reorderedCharts)
    }

    const toggleFullScreen = (chartId: string) => {
        setFullScreenChart((prev) => (prev === chartId ? null : chartId))
    }

    const updateChartConfig = (
        chartId: string,
        newConfig: Partial<ChartConfig>
    ) => {
        setChartConfigs((prevConfigs) => ({
            ...prevConfigs,
            [chartId]: {
                ...prevConfigs[chartId],
                ...newConfig,
            },
        }))
    }

    return (
        <PageContainer>
            {/* Lock/Unlock Button */}
            <Stack direction="row" justifyContent="space-between" mb={4}>
                <Heading size="lg">{translate('dashboard')}</Heading>
                <Button
                    leftIcon={isDragDisabled ? <FiUnlock /> : <FiLock />}
                    onClick={() => setIsDragDisabled((prev) => !prev)}
                    colorScheme={isDragDisabled ? 'green' : 'red'}
                    size="sm"
                >
                    {isDragDisabled ? 'Unlock Dashboard' : 'Lock Dashboard'}
                </Button>
            </Stack>

            {!fullScreenChart && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="chartsGrid"
                        direction="horizontal"
                        isDropDisabled={isDragDisabled}
                    >
                        {(provided) => (
                            <SimpleGrid
                                minChildWidth="lg"
                                gap={6}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {charts.map((chart, index) => (
                                    <Draggable
                                        key={chart.id}
                                        draggableId={chart.id}
                                        index={index}
                                        isDragDisabled={isDragDisabled}
                                    >
                                        {(provided) => (
                                            <Box
                                                borderRadius="lg"
                                                boxShadow="md"
                                                h="full"
                                                minH={'400'}
                                                overflow="hidden"
                                                p={4}
                                                bg={'white'}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps} // Entire widget draggable
                                                {...provided.dragHandleProps}
                                            >
                                                {/* Header Section */}
                                                <Flex
                                                    justifyContent="space-between"
                                                    mb={2}
                                                >
                                                    <Heading size="md">
                                                        {translate(
                                                            camelCase(chart.id)
                                                        )}
                                                    </Heading>
                                                    <Flex gap={2}>
                                                        <IconButton
                                                            aria-label="Configure Chart"
                                                            icon={
                                                                <FiSettings />
                                                            }
                                                            size="sm"
                                                            variant="ghost"
                                                            onClick={() => {
                                                                setSelectedChartForConfig(
                                                                    chart.id
                                                                )
                                                                setDrawerOpen(
                                                                    true
                                                                )
                                                            }}
                                                        />
                                                        <IconButton
                                                            aria-label="Toggle Full Screen"
                                                            icon={
                                                                <FiMaximize />
                                                            }
                                                            size="sm"
                                                            variant="ghost"
                                                            onClick={() =>
                                                                toggleFullScreen(
                                                                    chart.id
                                                                )
                                                            }
                                                        />
                                                    </Flex>
                                                </Flex>
                                                {/* Chart Component */}
                                                <Box h={'full'} pb={2}>
                                                    <chart.component
                                                        {...chartConfigs[
                                                            chart.id
                                                        ]}
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </SimpleGrid>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            {/* Fullscreen Chart */}
            {fullScreenChart && (
                <FullScreenChart
                    chartId={fullScreenChart}
                    charts={charts}
                    chartConfigs={chartConfigs}
                    onClose={() => setFullScreenChart(null)}
                />
            )}

            {/* Drawer for Configuring Charts */}
            {products?.length && (
                <ChartDrawer
                    isOpen={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    selectedChartForConfig={selectedChartForConfig}
                    chartConfigs={chartConfigs}
                    updateChartConfig={updateChartConfig}
                    products={products}
                />
            )}
        </PageContainer>
    )
}
