/* eslint-disable no-console */
import { AgChartOptions } from 'ag-charts-community'
import { AgCharts } from 'ag-charts-react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'

import { Box, Text } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../services/API/general.api'
import {
    ActivatedContractCountPerMonthDto,
    ProductType,
} from '../../../utils/types/types'
import LoadingChart from './LoadingChart.component'

const formatNumber = (number: number) => {
    if (number >= 1000000) return `${(number / 1000000).toFixed(1)}M`
    if (number >= 1000) return `${(number / 1000).toFixed(1)}k`
    return number.toString()
}

// Mock data as a fallback
const mockData: ActivatedContractCountPerMonthDto[] = [
    {
        year: 2024,
        month: 0, // January
        numberOfContracts: 25,
    },
    {
        year: 2024,
        month: 1, // February
        numberOfContracts: 30,
    },
    {
        year: 2024,
        month: 2, // March
        numberOfContracts: 28,
    },
    {
        year: 2024,
        month: 3, // April
        numberOfContracts: 35,
    },
    {
        year: 2024,
        month: 4, // May
        numberOfContracts: 40,
    },
    {
        year: 2024,
        month: 5, // June
        numberOfContracts: 38,
    },
    {
        year: 2024,
        month: 6, // July
        numberOfContracts: 45,
    },
    {
        year: 2024,
        month: 7, // August
        numberOfContracts: 50,
    },
    {
        year: 2024,
        month: 8, // September
        numberOfContracts: 42,
    },
    {
        year: 2024,
        month: 9, // October
        numberOfContracts: 48,
    },
    {
        year: 2024,
        month: 10, // November
        numberOfContracts: 55,
    },
    {
        year: 2024,
        month: 11, // December
        numberOfContracts: 60,
    },
]

// Transform API data into a format suitable for the chart
const transformData = (apiData: ActivatedContractCountPerMonthDto[]) => {
    const sortedData = apiData.sort(
        (a, b) => a.year - b.year || a.month - b.month
    )

    return sortedData.map((item) => ({
        month: new Date(item.year, item.month).toLocaleString('default', {
            month: 'short',
        }),
        contracts: item.numberOfContracts,
    }))
}

interface ActvatedContractChartProps {
    selectedProduct?: ProductType
    contractTemplate?: number
    startDate: Date
    endDate: Date
}

const ActivatedContractsChart = ({
    selectedProduct,
    contractTemplate,
    startDate,
    endDate,
}: ActvatedContractChartProps) => {
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await generalPostAPI(
                    API_ENDPOINTS.dashboardActivatedContracts,
                    {
                        productId: contractTemplate,
                        productType: selectedProduct,
                        startDate: format(startDate, 'yyyy-MM-dd'),
                        endDate: format(endDate, 'yyyy-MM-dd'),
                    }
                )
                if (!response.isOk) {
                    throw new Error('Network response was not ok')
                }
                const apiData: ActivatedContractCountPerMonthDto[] =
                    response.data
                setData(transformData(apiData))
            } catch (err) {
                console.error('Failed to fetch data:', err)
                setData(transformData(mockData)) // Use mock data if fetching fails
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [selectedProduct, contractTemplate, startDate, endDate])

    if (loading) {
        return <LoadingChart />
    }

    const options: AgChartOptions = {
        data,
        series: [
            {
                type: 'line',
                xKey: 'month',
                yKey: 'contracts',
                yName: 'Activated Contracts',
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: { rotation: 0 },
                title: {
                    text: 'Month',
                    enabled: true,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params: { value: number }) =>
                        formatNumber(params.value),
                },
                title: {
                    text: 'Contracts',
                    enabled: true,
                },
            },
        ],
        legend: {
            enabled: true,
            position: 'bottom',
        },
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            overflow="hidden"
        >
            <Box flex="1" display="flex">
                {data.length > 0 ? (
                    <AgCharts
                        options={options}
                        style={{ width: '98%', height: '98%' }}
                    />
                ) : (
                    <Text color="red.500">
                        No data available for rendering.
                    </Text>
                )}
            </Box>
        </Box>
    )
}

export default ActivatedContractsChart
