import { AgCharts } from 'ag-charts-react'
import { ReactElement, useEffect, useState } from 'react'

import { Box, useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import { baseSuccessToastOptions } from '../../../utils/functions.utils'
import { ContractsPaymentsPerMonthDto } from '../../../utils/types/types'
import LoadingChart from '../charts/LoadingChart.component'

const ContractsPaymentsPerMonthChart = (): ReactElement => {
    const toast = useToast()
    const [totalPaymentsData, setTotalPaymentsData] = useState<
        ContractsPaymentsPerMonthDto[]
    >([])
    const [loading, setLoading] = useState<boolean>(true)

    const getContractsPerProduct = async (): Promise<void> => {
        setLoading(true)
        try {
            const response: any = await generalGetAPI(
                API_ENDPOINTS.actualContractsPaymentPerMonth
            )
            if (response?.data && response?.isOk) {
                setTotalPaymentsData(response.data)
            }
            if (!response.isOk) {
                toast(baseSuccessToastOptions(response.message))
            }
        } catch (error: any) {
            toast(baseSuccessToastOptions(error?.message))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getContractsPerProduct()
    }, [])

    const createChartData = (): any[] =>
        totalPaymentsData.map((item) => ({
            yearMonth: `${item.year}-${String(item.month).padStart(2, '0')}`, // Ensure month formatting
            totalPayments: item.totalPeriodicPayments,
        }))

    const createOptions = (data: any[]): any => ({
        data,
        legend: {
            enabled: false, // Disable legend as we have one series
        },
        series: [
            {
                type: 'line',
                xKey: 'yearMonth',
                yKey: 'totalPayments',
                strokeWidth: 2,
                marker: {
                    enabled: true,
                    size: 6,
                },
                highlightStyle: {
                    item: {
                        fill: '#ff4081',
                        stroke: '#ff4081',
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                title: {
                    text: 'Month and Year',
                },
                label: {
                    rotation: -45, // Rotate for better readability
                    formatter: (params: any) => params.value.replace('-', '/'), // Replace YYYY-MM with YYYY/MM
                },
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: 'Total Payments',
                },
                label: {
                    formatter: (params: any) => `${params.value / 1000}k`,
                },
            },
        ],
        zoom: {
            enabled: true, // Enable zooming and panning
            axes: 'x', // Limit zooming to the x-axis
        },
        navigator: {
            enabled: true, // Enable the navigator
        },
        tooltip: {
            renderer: (params: any) => ({
                title: params.xValue,
                content: `Total Payments: ${params.yValue.toLocaleString()}`,
            }),
        },
        padding: {
            top: 10,
            left: 20,
            bottom: 40,
            right: 10,
        },
    })

    if (loading) {
        return <LoadingChart />
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            overflow="hidden"
        >
            <Box flex="1" display="flex">
                <AgCharts
                    options={createOptions(createChartData())}
                    style={{ width: '98%', height: '98%' }}
                />
            </Box>
        </Box>
    )
}

export default ContractsPaymentsPerMonthChart
