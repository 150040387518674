/* eslint-disable no-console */
import { AgCharts } from 'ag-charts-react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../services/API/general.api'
import { MonthlyFinancialSummary } from '../../../utils/types/types'
import LoadingChart from './LoadingChart.component'
import {
    formatNumber,
    monthlyRevenueOptions,
    MRRLineChartProps,
    transformData,
    TransformedData,
} from './monthlyRevenue.config'

const MRRLineChart = ({
    selectedProduct,
    contractTemplate,
    startDate,
    endDate,
}: MRRLineChartProps) => {
    const [data, setData] = useState<TransformedData[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [currentRevenue, setCurrentRevenue] = useState<number | null>(null)
    const [previousRevenue, setPreviousRevenue] = useState<number | null>(null)
    const [percentageIncrease, setPercentageIncrease] = useState<number | null>(
        null
    )
    const [previousRevenues, setPreviousRevenues] = useState<number[]>([])
    const backgroundColor = useColorModeValue('#fff', '#2d3748')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await generalPostAPI(
                    API_ENDPOINTS.dashboardMonthlyRevenue,
                    {
                        startDate: format(startDate, 'yyyy-MM-dd'),
                        endDate: format(endDate, 'yyyy-MM-dd'),
                        productId: contractTemplate,
                        productType: selectedProduct,
                    }
                )

                if (!response.isOk) {
                    throw new Error('Network response was not ok')
                }

                const apiData: MonthlyFinancialSummary[] = response.data
                handleChartData(apiData)
            } catch (err) {
                console.error('Failed to fetch data:', err)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [selectedProduct, contractTemplate, startDate, endDate])

    const handleChartData = (apiData: MonthlyFinancialSummary[]) => {
        const sortedChartData = [...apiData].sort(
            (entryA, entryB) =>
                entryA.year - entryB.year || entryA.month - entryB.month
        )
        setData(transformData(sortedChartData))

        const lastMonthRevenue =
            sortedChartData[sortedChartData.length - 1]?.revenue || 0
        const prevMonthRevenue =
            sortedChartData[sortedChartData.length - 2]?.revenue || 0
        const lastSixMonthsRevenues = sortedChartData
            .slice(-6)
            .reverse()
            .map((item) => item.revenue)

        setCurrentRevenue(lastMonthRevenue)
        setPreviousRevenue(prevMonthRevenue)
        setPreviousRevenues(lastSixMonthsRevenues)

        const increase =
            ((lastMonthRevenue - prevMonthRevenue) / prevMonthRevenue) * 100
        setPercentageIncrease(increase)
    }

    if (loading) {
        return <LoadingChart />
    }

    const options = monthlyRevenueOptions(data)

    return (
        <Box position="relative" height="100%" minH={'100%'}>
            <Flex
                height="calc(100% - 120px)"
                direction="column"
                bg={backgroundColor}
                borderRadius="lg"
                flex="1"
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
            >
                <Box flex="1" width="100%">
                    {data.length > 0 ? (
                        <AgCharts
                            options={options}
                            style={{ width: '98%', height: '98%' }}
                        />
                    ) : (
                        <Text color="red.500">
                            No data available for rendering.
                        </Text>
                    )}
                </Box>

                <Flex
                    position="absolute"
                    gap={4}
                    alignItems={'center'}
                    bottom="100px"
                    right="-6px"
                    bg="white"
                    p={2}
                    borderRadius="md"
                    boxShadow="md"
                >
                    <Text fontSize="md" fontWeight="bold">
                        ${formatNumber(currentRevenue as number)}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                        from ${formatNumber(previousRevenue as number)}
                    </Text>
                    <Text
                        fontSize="md"
                        fontWeight="bold"
                        color={
                            percentageIncrease && percentageIncrease > 0
                                ? 'green.500'
                                : 'red.500'
                        }
                    >
                        {percentageIncrease
                            ? `${percentageIncrease.toFixed(2)}%`
                            : ''}
                    </Text>
                </Flex>
            </Flex>

            <Flex
                mt={4}
                width="100%"
                bg={backgroundColor}
                p={4}
                borderRadius="lg"
                justifyContent="space-between"
                alignItems="center"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
                <Box>
                    <Text fontSize="xl" fontWeight="bold">
                        ${formatNumber(currentRevenue as number)}
                    </Text>
                    <Text color="gray.500">Current MRR</Text>
                </Box>
                {previousRevenues.map(
                    (rev, idx) =>
                        idx > 0 && (
                            <Box key={idx} textAlign="center">
                                <Text fontSize="lg" fontWeight="bold">
                                    ${formatNumber(rev)}
                                </Text>
                                <Text color="gray.500">
                                    {idx === 1
                                        ? '1 Month'
                                        : `${idx + 1} Months`}
                                </Text>
                            </Box>
                        )
                )}
            </Flex>
        </Box>
    )
}

export default MRRLineChart
