import React, { useEffect, useState, useCallback } from 'react'
import { AgCharts } from 'ag-charts-react'
import { Box, Text } from '@chakra-ui/react'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../../services/API/general.api'
import LoadingChart from '../charts/LoadingChart.component'

const transformData = (apiData: any[]) =>
    apiData
        .sort((a, b) => a.year - b.year || a.month - b.month)
        .map((item) => ({
            date: new Date(item.year, item.month - 1),
            churnRate: item.churnRate,
        }))

const ChurnRateChart = ({
    selectedProduct,
    contractTemplate,
}: {
    selectedProduct?: number
    contractTemplate?: number
}) => {
    const [chartData, setChartData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)

    const fetchChartData = useCallback(async () => {
        setLoading(true)
        setError(null)

        try {
            const payload: Record<string, any> = {}
            if (selectedProduct !== undefined) {
                payload.productId = selectedProduct
            }
            if (contractTemplate !== undefined) {
                payload.productType = contractTemplate
            }

            const response = await generalPostAPI(
                API_ENDPOINTS.dashboardCustomerChurnRate,
                payload
            )

            if (response.isOk && Array.isArray(response.data)) {
                const transformed = transformData(response.data)
                setChartData(transformed)
            } else {
                throw new Error('Failed to fetch valid data')
            }
        } catch (err) {
            setError((err as Error).message)
        } finally {
            setLoading(false)
        }
    }, [selectedProduct, contractTemplate])

    useEffect(() => {
        fetchChartData()
    }, [fetchChartData, selectedProduct, contractTemplate])

    const options = {
        data: chartData,
        series: [
            {
                type: 'line',
                xKey: 'date',
                yKey: 'churnRate',
                yName: 'Churn Rate',
                marker: { enabled: true },
                tooltip: {
                    renderer: ({ datum }: any) => ({
                        content: `${datum.date.toLocaleString('default', {
                            month: 'short',
                            year: 'numeric',
                        })}: ${datum.churnRate}%`,
                    }),
                },
            },
        ],
        axes: [
            {
                type: 'time',
                position: 'bottom',
                title: {
                    text: 'Month',
                },
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: 'Churn Rate (%)',
                },
                label: {
                    format: '{value}%',
                },
            },
        ],
    }

    if (loading) {
        return <LoadingChart />
    }

    if (error) {
        return <Text color="red.500">Error: {error}</Text>
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            overflow="hidden"
        >
            <Box flex="1" display="flex">
                <AgCharts
                    options={options as any}
                    style={{ width: '98%', height: '98%' }}
                />
            </Box>
        </Box>
    )
}

export default ChurnRateChart
