import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
    Box,
    Button,
    Flex,
    Heading,
    Select,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react'

import ContractAssetsTable from '../../../../../features/genericTables/contractAssetsTable/ContractAssetsTable.component'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../../services/API/general.api'
import { useProduct } from '../../../../../services/contexts/Product.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import {
    CustomerChangeContextProvider,
    useCustomerChange,
} from '../../../../../services/customer/CustomerChange.service'
import { baseErrorToastOptions } from '../../../../../utils/functions.utils'
import {
    AssetBalanceDTO,
    ContractAssetDTO,
    ContractAssetStatus,
    ContractDTO,
    CreateContractPreterminateOffer,
    CustomerDTO,
    PreterminateNumbersDTO,
    ProductType,
} from '../../../../../utils/types/types'
import CustomerTableComponent from '../../customer/CustomerTable.component'
import SelectPreterminationOffer from './SelectOffer.component'

dayjs.extend(isBetween)

function PreterminateContract(props: ModalSharedProps): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const { contract } = useContractService()
    const { products } = useProduct()
    const navigate = useNavigate()
    const [selectedAssets, setSelectedAssets] = useState<ContractAssetDTO[]>([])
    const [offerBalances, setOfferBalances] = useState<AssetBalanceDTO[]>([])
    const [preterminateOffers, setPreterminateOffers] =
        useState<ContractDTO[]>()
    const [offerAssetList, setOfferAssetList] = useState<any[]>([])
    const [period, setPeriod] = useState<number>(-1)
    const [contractAssets, setContractAssets] = useState<any[]>()
    const [preterminateNumbers, setPreterminateNumbers] = useState<
        PreterminateNumbersDTO[]
    >([])
    const [isCreateOffer, setIsCreateOffer] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState<Number | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const { customer, companyCustomers } = useCustomerChange()
    const [contractCustomer, setContractCustomer] = useState<CustomerDTO>()
    const [changeCustomer, setChangeCustomer] = useState(false)

    useEffect(() => {
        getOffer()
        getBalances()
        getEstimates()
    }, [contract])

    useEffect(() => {
        const customerIndex = companyCustomers?.findIndex(
            (item) => item?.customerNumber === contract.customerNumber
        )
        customerIndex !== -1 &&
            setContractCustomer(companyCustomers[customerIndex])
    }, [contract, companyCustomers])

    const findCurrentCustomer = useCallback((): string => {
        if (customer !== undefined) {
            return `${customer.name} - ${customer.email}`
        }
        return `${contractCustomer?.name} - ${contractCustomer?.email}`
    }, [customer, contractCustomer])

    useEffect(() => {
        if (contract?.contractAssets?.length) {
            setContractAssets(
                mergeAssetsWithPreterminateNumbers(
                    contract.contractAssets?.filter(
                        (ca) => ca.status === ContractAssetStatus.Active
                    ),
                    preterminateNumbers
                )
            )
        }
    }, [contract.contractNumber, preterminateNumbers])

    useEffect(() => {
        offerBalances.length > 0 && findCurrentMonth()
    }, [offerBalances])

    const onSelectionChanged = (data: any) => {
        const selectedNodes = data.api.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        if (JSON.stringify(selectedData) !== JSON.stringify(selectedAssets)) {
            setSelectedAssets([...selectedData])
        }
    }

    const getOffer = async (): Promise<void> => {
        setIsLoading(true)
        if (contract.contractNumber) {
            try {
                const response = await generalGetAPI(
                    API_ENDPOINTS.contractAssetsPreterminateOffer(
                        contract.contractNumber
                    )
                )
                if (response.isOk) {
                    if (response.data.length > 0) {
                        setPreterminateOffers(response.data)
                    } else {
                        setIsCreateOffer(true)
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
        setIsLoading(false)
    }

    const getEstimates = async (): Promise<void> => {
        setIsLoading(true)
        if (contract.contractNumber) {
            try {
                const response = await generalGetAPI(
                    API_ENDPOINTS.contractAssetsPreterminateOfferEstimate(
                        contract.contractNumber
                    )
                )
                if (response.isOk) {
                    if (response.data?.preterminateNumbers?.length > 0) {
                        setPreterminateNumbers(
                            response.data?.preterminateNumbers
                        )
                    } else {
                        setIsCreateOffer(true)
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
        setIsLoading(false)
    }

    const getBalances = async (): Promise<void> => {
        if (contract?.contractNumber) {
            try {
                setIsLoading(true)
                const response = await generalPostAPI(
                    API_ENDPOINTS.contractAssetsPreterminateOfferBalances(
                        contract.contractNumber
                    ),
                    {
                        contractNumber: contract.contractNumber,
                    }
                )
                if (response.isOk) {
                    setOfferBalances(response.data)
                }
            } catch (error) {
                toast(baseErrorToastOptions(translate('apiFail')))
                props.onClose()
                console.error(error)
            }
            setIsLoading(false)
        }
    }

    const createOffer = async (): Promise<void> => {
        if (contract?.contractNumber) {
            setIsLoading(true)
            try {
                const response = await generalPostAPI(
                    API_ENDPOINTS.contractAssetsPreterminateOffer(
                        contract.contractNumber
                    ),
                    {
                        contractNumber: contract.contractNumber,
                        productId: selectedProduct,
                        customerNumber:
                            customer?.customerNumber ||
                            contract?.customerNumber,
                        customerBoughtAsset: true,
                        month: period,
                        contractAssets: offerAssetList?.map((a) => ({
                            assetNumber: a.assetNumber,
                            agreedPrice: a.agreedRestValue,
                        })),
                    } as unknown as CreateContractPreterminateOffer
                )
                if (response.isOk) {
                    navigate(`/contracts/${response.data.contractNumber}`)
                    window.location.reload()
                } else {
                    toast(baseErrorToastOptions(response.message))
                }
            } catch (error) {
                setIsCreateOffer(true)
                toast(
                    baseErrorToastOptions(
                        translate('unableCreatePreterminationOffer')
                    )
                )
                console.error(error)
            }
            setIsLoading(false)
        }
    }

    const findCurrentMonth = () => {
        const index = offerBalances.findIndex((elem) => {
            if (
                dayjs(new Date()).isBetween(
                    elem.periodStartDate,
                    elem.periodEndDate
                )
            ) {
                return true
            }
            return false
        })
        if (index !== -1) {
            setPeriod(
                offerBalances[index].period === -1
                    ? 0
                    : offerBalances[index].period
            )
        }
        if (index === -1) {
            setPeriod(0)
        }
    }

    function updateOfferAssetList(updatedAsset: any) {
        const index = offerAssetList.findIndex(
            (a) => a.assetNumber === updatedAsset.assetNumber
        )
        if (index === -1) {
            setOfferAssetList([...offerAssetList, updatedAsset])
        } else {
            offerAssetList[index] = updatedAsset
            setOfferAssetList([...offerAssetList])
        }
    }

    function mergeAssetsWithPreterminateNumbers(
        assets: ContractAssetDTO[],
        preterminateNumbersValue: PreterminateNumbersDTO[]
    ) {
        // Create a dictionary for quick lookup of preterminate numbers by assetNumber
        const preterminateDict: { [key: string]: PreterminateNumbersDTO } = {}
        preterminateNumbersValue.forEach((item) => {
            if (item.assetNumber) {
                preterminateDict[item.assetNumber] = item
            }
        })

        // Iterate through each asset and merge with preterminate numbers if assetNumber matches
        const mergedAssets = assets.map((asset) => {
            const { assetNumber } = asset
            if (assetNumber && preterminateDict[assetNumber]) {
                return {
                    ...asset,
                    currentBalance:
                        preterminateDict[assetNumber].currentBalance,
                    remainingInterestEarnings:
                        preterminateDict[assetNumber].remainingInterestEarnings,
                    suggestedPreterminatePrice:
                        preterminateDict[assetNumber]
                            .suggestedPreterminatePrice,
                    expirationDate:
                        preterminateDict[assetNumber].expirationDate,
                }
            }
            return asset
        })
        return mergedAssets
    }

    return !isLoading ? (
        <div>
            {preterminateOffers && !isCreateOffer && (
                <Flex mb={4} gap={4} alignItems={'center'}>
                    <SelectPreterminationOffer offers={preterminateOffers} />
                    {translate('or')}
                    <Button
                        variant={'outline'}
                        onClick={() => setIsCreateOffer(true)}
                    >
                        {translate('createPreterminationOffer')}
                    </Button>
                </Flex>
            )}

            {isCreateOffer && (
                <>
                    <Heading size={'md'} mb={4}>
                        {translate('createPreterminationOffer')}
                    </Heading>

                    {changeCustomer && (
                        <Box mb={4}>
                            <Box w={'67%'}>
                                <Flex mb={4} gap={2} alignItems={'center'}>
                                    <Heading size={'md'}>
                                        {translate('customer')}
                                    </Heading>
                                    <Text fontSize="xs">
                                        {translate('optional')}
                                    </Text>
                                </Flex>
                            </Box>
                            <Flex mb={4} gap={2} alignItems={'center'}>
                                <Heading size={'sm'}>
                                    {translate('selectedCustomer')}
                                </Heading>
                                <Box>{findCurrentCustomer()}</Box>
                            </Flex>

                            <CustomerTableComponent
                                customers={companyCustomers}
                            />
                        </Box>
                    )}

                    <Button onClick={() => setChangeCustomer(!changeCustomer)}>
                        {!changeCustomer
                            ? translate('changeCustomer')
                            : translate('cancel')}
                    </Button>
                    <Heading size={'md'} mt={4}>
                        {translate('selectProduct')}
                    </Heading>
                    <Select
                        my={6}
                        placeholder={translate('selectProduct')}
                        onChange={(elem) =>
                            setSelectedProduct(Number(elem.target.value))
                        }
                        width={'70%'}
                    >
                        {products
                            ?.filter((p) => {
                                const isSale =
                                    p?.productType === ProductType.Sale

                                const validFromDateTime = new Date(
                                    p?.validFromDateTime
                                )
                                const validToDateTime = p?.validToDateTime
                                    ? new Date(p?.validToDateTime)
                                    : null
                                const now = new Date()

                                const isValid =
                                    now >= validFromDateTime &&
                                    (!validToDateTime || now <= validToDateTime)

                                return isSale && isValid
                            })
                            ?.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {`${product.name}`}
                                </option>
                            ))}
                    </Select>
                    {contractAssets?.length && (
                        <ContractAssetsTable
                            assets={contractAssets}
                            cellValueChanged={(event: any) => {
                                if (event.data) {
                                    updateOfferAssetList({ ...event.data })
                                }
                            }}
                            hasSearch={false}
                            hasSelection={true}
                            onSelectionChanged={onSelectionChanged}
                            variation="preterminate"
                        />
                    )}
                    <Flex gap={4} justifyContent={'flex-end'} mt={8}>
                        <Button
                            onClick={() => {
                                !preterminateOffers && props.onClose()
                                setIsCreateOffer(false)
                            }}
                            variant={'outline'}
                        >
                            {translate('cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                setIsCreateOffer(false)
                                createOffer()
                            }}
                            isDisabled={
                                !selectedProduct ||
                                selectedAssets.length === 0 ||
                                offerAssetList.length === 0 ||
                                offerAssetList?.some(
                                    (a) => !a?.agreedRestValue // || !a?.fee
                                )
                            }
                        >
                            {translate('createOffer')}
                        </Button>
                    </Flex>
                </>
            )}
        </div>
    ) : (
        <Flex justifyContent={'center'} height={'400px'} alignItems={'center'}>
            <Spinner />
        </Flex>
    )
}

const PreterminateComponentWithContext = (props: ModalSharedProps) => (
    <CustomerChangeContextProvider>
        <PreterminateContract {...props} />
    </CustomerChangeContextProvider>
)

export default withModalHOC(PreterminateComponentWithContext)
