import { AgCharts } from 'ag-charts-react'
import { ReactElement, useEffect, useState } from 'react'

import { Box, useToast } from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import { baseSuccessToastOptions } from '../../../utils/functions.utils'
import { ContractsPaymentsPerMonthDto } from '../../../utils/types/types'
import LoadingChart from '../charts/LoadingChart.component'

const ForecastContractsPaymentsPerMonthChart = (): ReactElement => {
    const translate = useTranslation().t
    const toast = useToast()
    const [totalPaymentsData, setTotalPaymentsData] = useState<
        ContractsPaymentsPerMonthDto[]
    >([])
    const [loading, setLoading] = useState<boolean>(true)

    const getContractsPerProduct = async (): Promise<void> => {
        try {
            setLoading(true)
            const response: any = await generalGetAPI(
                API_ENDPOINTS.forecastContractsPaymentPerMonth
            )
            if (response?.data && response?.isOk) {
                setTotalPaymentsData(response.data)
            }
            if (!response.isOk) {
                toast(baseSuccessToastOptions(response.message))
            }
        } catch (error: any) {
            toast(baseSuccessToastOptions(error?.message))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getContractsPerProduct()
    }, [])

    const createChartData = (): any[] =>
        totalPaymentsData.map((item) => ({
            yearMonth: `${item.year}-${String(item.month).padStart(2, '0')}`, // Format month for consistency
            totalPayments: item.totalPeriodicPayments,
        }))

    const createOptions = (data: any[]): any => ({
        data,
        legend: {
            enabled: false,
        },
        series: [
            {
                type: 'line',
                xKey: 'yearMonth',
                yKey: 'totalPayments',
                yName: translate('totalPayments'),
                strokeWidth: 2,
                marker: {
                    enabled: true,
                    size: 6,
                },
                highlightStyle: {
                    item: {
                        fill: '#ff4081',
                        stroke: '#ff4081',
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                title: {
                    text: translate('monthAndYear'),
                    fontSize: 12,
                },
                label: {
                    rotation: -45, // Rotate labels for better readability
                    formatter: (params: any) =>
                        params.value.split('-').join('/'), // Convert YYYY-MM to YYYY/MM
                },
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: translate('totalPayments'),
                    fontSize: 12,
                },
                label: {
                    formatter: (params: any) => `${params.value / 1000}k`, // Format to "k"
                },
            },
        ],
        zoom: {
            enabled: true, // Enable zooming
            axes: 'x',
        },
        navigator: {
            enabled: true, // Add a navigator for zooming and panning
        },
        tooltip: {
            enabled: true,
            renderer: (params: any) => ({
                title: translate('monthAndYear'),
                content: `${
                    params.xValue
                }: ${params.yValue.toLocaleString()} ${translate('currency')}`,
            }),
        },
    })

    if (loading) {
        return <LoadingChart />
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            overflow="hidden"
        >
            <Box flex="1" display="flex">
                <AgCharts
                    options={createOptions(createChartData())}
                    style={{ width: '98%', height: '98%' }}
                />
            </Box>
        </Box>
    )
}

export default ForecastContractsPaymentsPerMonthChart
