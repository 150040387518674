import { Box, Flex, Heading, IconButton } from '@chakra-ui/react'
import { FiMinimize } from 'react-icons/fi'

const FullScreenChart = ({
    chartId,
    charts,
    chartConfigs,
    onClose,
}: {
    chartId: string
    charts: { id: string; component: React.ElementType }[]
    chartConfigs: Record<string, any>
    onClose: () => void
}) => {
    const chart = charts.find((c) => c.id === chartId)

    if (!chart) return null

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            backgroundColor="white"
            zIndex={1000}
            p={4}
        >
            <Flex justifyContent="space-between" mb={2}>
                <Heading size="md">{chartId}</Heading>
                <IconButton
                    aria-label="Exit Full Screen"
                    icon={<FiMinimize />}
                    size="md"
                    variant="ghost"
                    onClick={onClose}
                />
            </Flex>
            <chart.component {...chartConfigs[chartId]} />
        </Box>
    )
}

export default FullScreenChart
