import { AgCharts } from 'ag-charts-react'
import camelCase from 'lodash/camelCase'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Select, useToast } from '@chakra-ui/react'
import groupBy from 'lodash/groupBy'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import {
    baseSuccessToastOptions,
    toCamelCase,
} from '../../../utils/functions.utils'
import {
    ContractStatus,
    CustomerContractsPerStatusDto,
} from '../../../utils/types/types'
import LoadingChart from '../charts/LoadingChart.component'

const CustomerContractsPerStatusChart = (): ReactElement => {
    const translate = useTranslation().t
    const toast = useToast()
    const [contractsPerStatus, setContractsPerStatus] = useState<any[]>([])
    const [selectedCustomer, setSelectedCustomer] = useState('')
    const [customers, setCustomers] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)

    const series = Object.keys(ContractStatus)
        .filter((item) => Number.isNaN(Number(item)))
        .map((s) => ({
            type: 'bar',
            xKey: 'customerName',
            yKey: translate(toCamelCase(s)),
            yName: translate(toCamelCase(s)),
        }))

    const setSeriesAndContracts = (data: CustomerContractsPerStatusDto[]) => {
        const entriesPerCustomer = groupBy<CustomerContractsPerStatusDto>(
            data,
            'customerName'
        )
        const items = []
        const keys = Object.keys(ContractStatus)
            .filter((item) => Number.isNaN(Number(item)))
            .reduce(
                (prev, curr) => ({
                    ...prev,
                    [translate(toCamelCase(curr))]: 0,
                }),
                {}
            )
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, values] of Object.entries(entriesPerCustomer)) {
            items.push(
                values.reduce(
                    (prev, curr) => ({
                        ...prev,
                        [translate(camelCase(ContractStatus[curr?.status]))]:
                            curr.totalContractsNumber,
                    }),
                    { ...keys, customerName: key }
                )
            )
        }
        setContractsPerStatus(items)
    }

    const getCustomerContractsPerStatus = async (): Promise<void> => {
        setLoading(true)
        try {
            const response: any = await generalGetAPI(
                API_ENDPOINTS.dashboardCustomerContractsPerStatus
            )
            if (response?.data && response?.isOk) {
                setCustomers([
                    ...new Set(
                        response.data.map((item: any) => item?.customerName)
                    ),
                ])
                if (response?.data?.length) {
                    setSelectedCustomer(response.data[0].customerName)
                }
                setSeriesAndContracts(response.data)
            }
            if (!response.isOk) {
                toast(baseSuccessToastOptions(response.message))
            }
        } catch (error: any) {
            toast(baseSuccessToastOptions(error?.message))
        } finally {
            setLoading(false)
        }
    }

    const createChartData = (): any[] =>
        // Convert your DTO data into the format expected by AG-Grid charts
        contractsPerStatus.filter(
            (item) => item.customerName === selectedCustomer
        )

    useEffect(() => {
        getCustomerContractsPerStatus()
    }, [])

    const createOptions = (data: any[]): any => ({
        data,
        legend: {
            enabled: true,
        },
        series,
        tick: {
            interval: 5,
        },
    })

    if (loading) {
        return <LoadingChart />
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            overflow="hidden"
        >
            <Select
                placeholder={translate('select')}
                value={selectedCustomer}
                onChange={(e) => {
                    setSelectedCustomer(e.target.value)
                }}
            >
                {customers.map((option: string, index: number) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </Select>
            <Box flex="1" display="flex">
                <AgCharts
                    options={createOptions(createChartData())}
                    style={{ width: '98%', height: '98%' }}
                />
            </Box>
        </Box>
    )
}

export default CustomerContractsPerStatusChart
