import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Select,
} from '@chakra-ui/react'

const ChartDrawer = ({
    isOpen,
    onClose,
    selectedChartForConfig,
    chartConfigs,
    updateChartConfig,
    products,
}: {
    isOpen: boolean
    onClose: () => void
    selectedChartForConfig: string | null
    chartConfigs: Record<string, any>
    updateChartConfig: (chartId: string, newConfig: Record<string, any>) => void
    products: any[]
}) => {
    if (!selectedChartForConfig) return null

    const config = chartConfigs[selectedChartForConfig]

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent minW={'sm'}>
                <DrawerCloseButton />
                <DrawerHeader>Configure {selectedChartForConfig}</DrawerHeader>
                <DrawerBody>
                    <FormControl mb={4}>
                        <FormLabel>Start Date</FormLabel>
                        <Input
                            type="date"
                            value={
                                config.startDate?.toISOString()?.split('T')[0]
                            }
                            onChange={(e) =>
                                updateChartConfig(selectedChartForConfig, {
                                    startDate: new Date(e.target.value),
                                })
                            }
                        />
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>End Date</FormLabel>
                        <Input
                            type="date"
                            value={config.endDate?.toISOString()?.split('T')[0]}
                            onChange={(e) =>
                                updateChartConfig(selectedChartForConfig, {
                                    endDate: new Date(e.target.value),
                                })
                            }
                        />
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Product</FormLabel>
                        <Select
                            value={config.selectedProduct || ''}
                            onChange={(e) =>
                                updateChartConfig(selectedChartForConfig, {
                                    selectedProduct: e.target.value || null,
                                })
                            }
                        >
                            <option value="">All</option>
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </DrawerBody>
                <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme="blue" onClick={onClose}>
                        Apply
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default ChartDrawer
